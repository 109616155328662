import ClipboardJS from 'clipboard/dist/clipboard';

document.addEventListener("turbolinks:load", function() {
  var clipboard = new ClipboardJS('.copy-text');
  clipboard.on('success', function(e) {
    var alertMessage = $(e.trigger).data('clipboard-alert') || "Copied";

    var popoverElement = $(e.trigger);
    popoverElement.popover({
      content: alertMessage, trigger: 'manual', placement: 'bottom'
    });
    popoverElement.popover('show');
    setTimeout(function() {
      popoverElement.popover('hide');
    }, 1000);
  });
});
