document.addEventListener("turbolinks:load", function() {
  $('.read-more').addClass('read-more--collapsed');
  $('.read-more button').on('click', function(e) {
    $(this)
      .closest('.read-more')
      .toggleClass('read-more--collapsed');

    if ($(this).closest('.read-more').hasClass('read-more--collapsed')) {
      $(this).text("Read more");
    } else {
      $(this).text("Read less");
    }

    e.preventDefault();
  });
});
