document.addEventListener("turbolinks:load", function() {
  $('.runner-avatar-tooltip').tooltip({
    toggle: "tooltip", placement: "bottom", html: true
  })

  $('.runner-avatar-tooltip[data-runner]').on('shown.bs.tooltip', function(e) {
    var currentLink = $(this)
    if (currentLink.data('loaded')) { return }

    var runner = currentLink.data('runner');
    var describedByContent = $('#' + currentLink.attr('aria-describedby'))
      .find('.tooltip-inner')

    $.get('/runners/' + runner + '/streaks', function(data) {
      var name = currentLink.data('original-title')
      var links = $('a[data-runner="'+ runner +'"]')
      links.attr('data-original-title', name + data)

      describedByContent.html(name + data)
      currentLink.tooltip('update')
    })

    $('a[data-runner="'+ runner +'"]').data("loaded", true)
  })
})
