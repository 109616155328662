/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from "expose-loader?exposes=$,jQuery!jquery";
import "regenerator-runtime/runtime";
import "bootstrap/dist/js/bootstrap";
import flatpickr from "flatpickr";
import "select2";
import "controllers";
import "./src/vendor/cookies_eu";

import "./src/add_to_calendar";
import "./src/clipboard";
import "./src/odometer";
import "./src/read_more";
import "./src/runner_avatar";
import "./src/social";
import "./src/weekly_calendar";
import "./src/weekly_schedule";

require("@rails/ujs").start();
require("turbolinks").start();

document.addEventListener("turbolinks:load", function () {
  $('[data-toggle="tooltip"]').tooltip();
  $(".select2").select2({ theme: "classic" });
  $("#js-alerts").hide();
  flatpickr('input[type="datetime-local"]', {
    enableTime: true,
    time_24hr: true,
  });
});

global.$ = jQuery;
