import Ouical from './vendor/ouical.min';

document.addEventListener("turbolinks:load", function() {
  $('.add-to-calendar').each(function(element) {
    var calendar = Ouical.createCalendar({
      options: {
        class: 'my-calendar-event'
      },
      data: {
        title: this.dataset.title,
        start: new Date(this.dataset.time),
        duration: this.dataset.duration || 105,
        address: this.dataset.address,
        description: ''
      }
    });
    this.appendChild(calendar);

    $('.icon-outlook').hide();
  });
});
