// based on https://github.com/stimulus-components/stimulus-scroll-to under MIT licence
import { Controller } from "stimulus";
import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

export default class extends Controller {
  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.scroll);

    this.offset = this.offsetValue || this.defaultOptions.offset || 0;
    this.behavior =
      this.behaviorValue || this.defaultOptions.behavior || "smooth";
  }

  disconnect() {
    this.element.removeEventListener("click", this.scroll);
  }

  scroll(event) {
    event.preventDefault();

    const id = this.element.hash.replace(/^#/, "");
    const target = document.getElementById(id);

    if (!target) {
      console.warn(
        `[stimulus-scroll-to] The element with the id: "${id}" does not exist on the page.`
      );
      return;
    }

    const elementPosition =
      target.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition - this.offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior,
    });
  }

  get defaultOptions() {
    return {};
  }
}
