document.addEventListener('turbolinks:load', function () {
  $('.weekly-schedule button[data-day]').on('click', function (e) {
    var day = $(this).data('day')
    var days = $('.weekly-schedule input[type=checkbox][data-day=' + day + ']')
    var daysChecked = days.map(function (e) { return this.checked })
    var anyChecked = $.makeArray(daysChecked).includes(true)
    if (anyChecked) {
      days.prop('checked', false)
    } else {
      days.prop('checked', true)
    }
  })
  $('.weekly-schedule button[data-time]').on('click', function (e) {
    var time = $(this).data('time')
    var times = $('.weekly-schedule input[type=checkbox][data-time="' + time + '"]')
    var timesChecked = times.map(function (e) { return this.checked })
    var anyChecked = $.makeArray(timesChecked).includes(true)
    if (anyChecked) {
      times.prop('checked', false)
    } else {
      times.prop('checked', true)
    }
  })

  $('#runner_location_weekly_schedule_preset').on('change', function (e) {
    $('.weekly-schedule input[type=checkbox]').prop('checked', false)

    if (this.value == '{}') {
      $('.weekly-schedule-collapse').collapse('show')
    }

    var defaults = JSON.parse(this.value)
    if (defaults === null) { return }

    Object.keys(defaults).forEach(function (day) {
      var hours = defaults[day]
      Object.keys(hours).forEach(function (hour) {
        if (hours[hour] > 0) {
          $('input[name="runner_location[weekly_schedule][' + day + '][' + hour + ']"]').prop('checked', true)
        }
      })
    })
  })
})
