document.addEventListener("turbolinks:load", function() {
  $('.share__social-btn').on("click", function(e) {
    e.preventDefault();

    // Set values for window
    var width = '500';
    var height = '400';
    var resize = 'yes';

    // Set title and open popup with focus on it
    var strTitle = ((typeof $(this).attr('title') !== 'undefined') ? $(this).attr('title') : 'Social Share'),
    strParam = 'width=' + width + ',height=' + height + ',resizable=' + resize,
    objWindow = window.open($(this).attr('href'), strTitle, strParam).focus();
  });
});
