import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.inputTarget.style.resize = 'none'
    this.inputTarget.style.minHeight = `${Math.max(
      this.inputTarget.scrollHeight,
      34
    )}px`
    this.inputTarget.style.overflow = 'hidden'
  }

  resize(event) {
    event.target.style.height = '6px'
    event.target.style.height = `${event.target.scrollHeight}px`
  }

  submitOnEnter(event) {
    if (
      event.which === 13 &&
      !event.shiftKey &&
      event.target.value.trim() !== ''
    ) {
      const form = event.target.closest('form')
      if (!form.classList.contains('submitting')) {
        form.classList.add('submitting')
        Rails.fire(form, 'submit')
        event.preventDefault()
      }
    }
  }
}
