document.addEventListener('turbolinks:load', function () {
  $('.weekly-calendar button[data-day]').on('click', function (e) {
    var day = $(this).data('day')
    var days = $('.weekly-calendar input[type=checkbox][data-day=' + day + ']')
    var daysChecked = days.map(function (e) { return this.checked })
    var anyChecked = $.makeArray(daysChecked).includes(true)
    if (anyChecked) {
      days.prop('checked', false).change()
    } else {
      days.prop('checked', true).change()
    }
  })
  $('.weekly-calendar button[data-time]').on('click', function (e) {
    var time = $(this).data('time')
    var week = $(this).data('week')
    var times = $('.weekly-calendar input[type=checkbox][data-time="' + time + '"][data-week="' + week + '"]')
    var timesChecked = times.map(function (e) { return this.checked })
    var anyChecked = $.makeArray(timesChecked).includes(true)
    if (anyChecked) {
      times.prop('checked', false).change()
    } else {
      times.prop('checked', true).change()
    }
  })

  var limit = 18
  $(".weekly-calendar input[type='checkbox']").on('change', function (e) {
    const itemsChecked = $(".weekly-calendar input[type='checkbox']:checked")
    let timesChecked = itemsChecked.map((index, e) => { return new Date($(e).val()) }).get();
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    let isWithinOneDay = timesChecked.some((time) => time < tomorrow )

    var items_checked_amount = $(".weekly-calendar input[type='checkbox']:checked").length
    var submit_btn = $('#new_mission_referral input[type=submit]')

    if (items_checked_amount == 0) {
      submit_btn.attr('data-confirm', submit_btn.data('confirm_none'))
    } else if (isWithinOneDay) {
      submit_btn.attr('disabled', false)
      submit_btn.attr('data-confirm', submit_btn.data('confirm_too_soon'))
    } else if (items_checked_amount == 1) {
      submit_btn.attr('disabled', false)
      submit_btn.attr('data-confirm', submit_btn.data('confirm_one'))
    } else {
      submit_btn.attr('disabled', false)
      submit_btn.attr('data-confirm', submit_btn.data('confirm_many'))
    }

    if (items_checked_amount > limit) {
      this.checked = false
      $('.weekly-calendar-limit').collapse('show')
    } else {
      $('.weekly-calendar-limit').collapse('hide')
    }
  })

  $('form#new_mission_referral').submit(function (e) {
    if ($('.weekly-calendar').length) {
      var items_checked = $('.weekly-calendar input[type="checkbox"]:checked').length
      if (items_checked == 0) {
        e.preventDefault()
      }
    }
  })
})
